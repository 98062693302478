import React, { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { Building, College } from "src/lib/interfaces"; // import the College interface
import { db } from "src/firebase";
import washer from "src/static/icons/washer.png";
import dryer from "src/static/icons/dryer.png";

const CollegeComponent = ({ domain }: { domain: string }) => {
  const [collegeData, setCollegeData] = useState<College | null>(null);
  const [selectedBuilding, setSelectedBuilding] = useState<Building | null>(
    null
  );

  const handleBuildingChange = (newBuilding: Building) => {
    setSelectedBuilding(newBuilding);
  };

  useEffect(() => {
    const unsub = onSnapshot(
      doc(db, "schools", domain),
      (doc) => {
        if (doc.exists()) {
          const fetchedCollegeData = doc.data() as College;
          setCollegeData(fetchedCollegeData);
          // Set the first building as selected if it exists
          if (
            fetchedCollegeData.buildings &&
            fetchedCollegeData.buildings.length > 0
          ) {
            setSelectedBuilding(fetchedCollegeData.buildings[0]);
          }
        } else {
          console.log("No such document!");
        }
      },
      (error) => {
        console.error("Error getting document: ", error);
      }
    );

    return () => unsub();
  }, [domain]);

  if (!collegeData) {
    return (
      <div>
        <CollegeLoadingSkeleton />
      </div>
    );
  }

  return (
    <div>
      <BuildingSelector
        buildings={collegeData.buildings}
        selectedBuilding={selectedBuilding}
        onSelectBuilding={handleBuildingChange}
      />

      {selectedBuilding ? (
        <div className="space-y-2 sm:space-y-4">
          <div className="sm:px-8 md:px-16 lg:px-32">
            <h3 className="text-center sm:text-left text-lg font-semibold mb-2">
              Washers:
            </h3>
            <div className="flex justify-center sm:justify-start">
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {selectedBuilding &&
                  selectedBuilding.washingmachines &&
                  selectedBuilding.washingmachines.map((machine, index) => (
                    <Washer
                      key={index}
                      isInUse={machine.isInUse}
                      lastUpdated={
                        machine.lastUpdated?.toMillis
                          ? machine.lastUpdated.toMillis()
                          : 0
                      }
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="sm:px-8 md:px-16 lg:px-32">
            <h3 className="text-center sm:text-left text-lg font-semibold mb-2">
              Dryers:
            </h3>
            <div className="flex justify-center sm:justify-start">
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {selectedBuilding &&
                  selectedBuilding.dryers &&
                  selectedBuilding.dryers.map((machine, index) => (
                    <Dryer
                      key={index}
                      isInUse={machine.isInUse}
                      lastUpdated={
                        machine.lastUpdated?.toMillis
                          ? machine.lastUpdated.toMillis()
                          : 0
                      }
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p className="mt-4">
          Please select a building to view laundry machines.
        </p>
      )}
    </div>
  );
};

export default CollegeComponent;

interface BuildingSelectorProps {
  buildings: Building[];
  selectedBuilding: Building | null;
  onSelectBuilding: (building: Building) => void;
}

const BuildingSelector = ({
  buildings,
  selectedBuilding,
  onSelectBuilding,
}: BuildingSelectorProps) => {
  return (
    <div className="sm:flex  sm:justify-center">
      <div className="flex overflow-x-auto whitespace-nowrap py-4 px-4 relative">
        {buildings.map((buildingOption) => (
          <div
            key={buildingOption.id}
            className="flex flex-col items-center mr-4 cursor-pointer"
            onClick={() => onSelectBuilding(buildingOption)}
          >
            <div
              className={`absolute font-medium text-zinc-500 ${
                buildingOption === selectedBuilding
                  ? "opacity-0"
                  : "opacity-100"
              }`}
            >
              {buildingOption.name}
            </div>

            <div
              className={`font-bold ${
                buildingOption !== selectedBuilding
                  ? "opacity-0"
                  : "opacity-100"
              }`}
            >
              {buildingOption.name}
            </div>

            {buildingOption === selectedBuilding && (
              <div className="h-1.5 w-1.5 bg-black rounded-full mt-1"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const Washer = ({
  isInUse,
  lastUpdated,
}: {
  isInUse: boolean;
  lastUpdated: number;
}) => {
  const minutes = 0.1; // 6 sec
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const isOffline = currentTime - lastUpdated > minutes * 60 * 1000;

  return (
    <div
      className={`w-44 h-44 rounded-3xl ${
        isOffline ? "bg-gray-400" : isInUse ? "bg-red-400" : "bg-green-400"
      }`}
    >
      <div className="flex flex-col items-start justify-start p-4 h-full">
        <img src={washer} alt="Washing Machine" className="w-14 h-14" />
        <p className="text-black font-bold py-2 pb-2">Washer</p>
        <div className="bg-white rounded-full px-4 py-1 shadow-md mt-2">
          <p className="text-black font-bold">
            {isOffline ? "Offline" : isInUse ? "In Use" : "Available"}
          </p>
        </div>
      </div>
    </div>
  );
};

const Dryer = ({
  isInUse,
  lastUpdated,
}: {
  isInUse: boolean;
  lastUpdated: number;
}) => {
  const isOffline = Date.now() - lastUpdated > 3600000; // 1 hour
  return (
    <div
      className={`w-44 h-44 rounded-3xl ${
        isOffline ? "bg-gray-400" : isInUse ? "bg-red-400" : "bg-green-400"
      }`}
    >
      <div className="flex flex-col items-start justify-start p-4 h-full">
        <img src={dryer} alt="Dryer" className="w-14 h-14" />
        <p className="text-black font-bold py-2 pb-2">Dryer</p>
        <div className="bg-white rounded-full px-4 py-1 shadow-md mt-2">
          <p className="text-black font-bold">
            {isOffline ? "Offline" : isInUse ? "In Use" : "Available"}
          </p>
        </div>
      </div>
    </div>
  );
};

const MachineSkeleton = () => {
  return (
    <div className="animate-pulse">
      <div className="w-44 h-44 rounded-3xl animate-pulse bg-gray-200"></div>
    </div>
  );
};

const BuildingSelectorSkeleton = () => {
  return (
    <div className="sm:flex  sm:justify-center">
      <div className="flex overflow-x-auto whitespace-nowrap py-4 px-4 relative">
        {[...Array(5)].map((_, index) => (
          <div
            key={index}
            className="flex flex-col items-center mr-4 cursor-pointer animate-pulse"
          >
            {/* Placeholder for building name */}
            <div className="h-6 w-24 bg-gray-200 mt-2 rounded"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

const CollegeLoadingSkeleton = () => {
  return (
    <div>
      <BuildingSelectorSkeleton />

      <div className="space-y-2 sm:space-y-4">
        <div className="sm:px-8 md:px-16 lg:px-32">
          <h3 className="text-center sm:text-left text-lg font-semibold mb-2">
            Washers:
          </h3>
          <div className="flex justify-center sm:justify-start">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {Array.from({ length: 4 }).map(
                (
                  _,
                  index // Assuming 4 as a placeholder number
                ) => (
                  <MachineSkeleton />
                )
              )}
            </div>
          </div>
        </div>
        <div className="sm:px-8 md:px-16 lg:px-32">
          <h3 className="text-center sm:text-left text-lg font-semibold mb-2">
            Dryers:
          </h3>
          <div className="flex justify-center sm:justify-start">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {Array.from({ length: 4 }).map(
                (
                  _,
                  index // Assuming 4 as a placeholder number
                ) => (
                  <MachineSkeleton />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
