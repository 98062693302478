import { onAuthStateChanged, User } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "src/firebase";
import SignInPage from "src/components/SignInPage";
import UserProfile from "src/components/UserProfile";
import CollegeComponent from "./CollegeComponent";

function App() {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);
  const signOut = () => {
    auth
      .signOut()
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  };

  if (isLoading) {
    return <div>{/* <LoadingSkeleton /> */}</div>;
  }

  if (!user) {
    return <SignInPage />;
  }

  if (user != null) {
    if (user.email) {
      const domain = getEmailDomain(user.email);
      return (
        <div className="bg-slate-100 min-h-screen">
          <div className="bg-white rounded-b-[36px] py-4">
            <UserProfile
              name={user.displayName ?? ""}
              photo={user.photoURL ?? ""}
              signout={signOut}
            />
          </div>
          <CollegeComponent domain={domain} />
        </div>
      );
    }
  }

  return (
    <div>
      <p>Please log out and log back in, unknow error</p>
      <button onClick={signOut}>Sign out</button>
    </div>
  );
}

export default App;

function getEmailDomain(email: string) {
  return email.substring(email.lastIndexOf("@") + 1);
}
