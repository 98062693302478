import { Search } from "lucide-react";
import { useState } from "react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import placeholder from "src/static/placeholderpfp.png";
// import SearchIcon from your icon library or assets
// Assuming you have authentication and sign-out logic set up

type UserProfileProps = {
  name: string;
  photo: string;
  signout: () => void;
};

const UserProfile = ({ name, photo, signout }: UserProfileProps) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const dateString = new Date().toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });

  return (
    <div className="flex items-center py-4">
      <div className="w-1/4 flex justify-center items-center relative">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <img
              className="w-[60px] h-[60px] rounded-full object-cover cursor-pointer"
              src={photo || placeholder}
              alt="Profile"
              onClick={() => setShowDropdown(!showDropdown)}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={signout}>Log Out</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="w-1/2 flex flex-col justify-center items-center text-center">
        <div className="text-xl font-semibold text-gray-900">Hello, {name}</div>
        <div className="text-sm text-gray-700">{dateString}</div>
      </div>
      <div className="w-1/4 flex justify-center items-center">
        <Search size={24} />
      </div>
    </div>
  );
};

export default UserProfile;
